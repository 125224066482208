




















































































































































































@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

.header-right {
  display: flex;
}
.header-right,
.header-left {
  width: 2rem;
}
.drawer-title {
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

@include breakpoint-lg() {
  .header-left {
    display: none;
  }
  .header-right,
  .header-left {
    --btn-link-color: var(--color-white);

    width: auto;
    color: var(--color-white);
  }
  .drawer-title {
    font-weight: normal;
    justify-content: flex-start;
  }
}
