























































































































































































































































































































































































































































































































































































































































































































































































@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';
.comment-highlight .comment-card {
  background-color: var(--color-grey-base);
  padding: 10px;
}
.comments-container {
  border-top: 1px solid $color-grey-darker;
  border-bottom: 1px solid $color-grey-darker;
  padding-top: 10px;
  padding-bottom: 10px;
  textarea.form-control {
    padding-right: 55px;
    height: 50px;
    .media-detail-modal & {
      background: $color-grey-lighter;
    }
  }
  .comment-actions {
    .btn-link {
      font-weight: bold;
    }
  }
  .comments {
    margin-top: 10px;
  }
  .reply-comment {
    margin-left: 10px;
    .theme-employee & {
      color: $color-primary-dark-employee;
    }
    .theme-guardian & {
      color: $color-primary-dark-guardian;
    }
    .theme-child & {
      color: $color-primary-dark-child;
    }
    .theme-admin & {
      color: $color-primary-dark-admin;
    }
  }
  .comments-load-more {
    background: $color-grey-base;
    padding: 15px;
    cursor: pointer;
    margin-bottom: 20px;
    .theme-employee & {
      color: $color-primary-dark-employee;
    }
    .theme-guardian & {
      color: $color-primary-dark-guardian;
    }
    .theme-child & {
      color: $color-primary-dark-child;
    }
    .theme-admin & {
      color: $color-primary-dark-admin;
    }
  }
  .comment {
    display: flex;
    gap: 0.5rem;
    .comment-data {
      flex: 1;
      padding-top: 8px;
      font-size: 13px;
    }
    .comment-content {
      font-size: 15px;
    }
  }
  .add-comment {
    position: relative;
    .add-comment-btn {
      position: absolute;
      right: 20px;
      bottom: 14px;
      z-index: 1000;
    }
  }

  /deep/ .user-icon {
    width: 40px !important;
    height: 40px;
    span {
      width: 40px;
      height: 40px;
    }
  }
}
