




































.contact-profile-actions-variables {
  --text-decoration: underline;
  --font-weight: bold;
}
.contact-profile-actions {
  .action-button {
    --btn-link-color: var(--color-primary-dark);
    font-size: 1rem;
    .button-text {
      text-decoration: var(--text-decoration);
      font-weight: var(--font-weight);
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
