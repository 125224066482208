





































































































































.contact-popover {
  border: none;
  box-shadow: var(--box-shadow-base);
  margin-bottom: 4px;
  margin-left: -1.4rem;
  min-width: 400px;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  /deep/.arrow {
    display: none;
  }
  /deep/.popover-body {
    min-height: 152px;
    padding: 1.5rem;
    color: var(--color-primary-darker);
    display: flex;
  }
}
.contact-card {
  display: flex;
  gap: 1.5rem;
  min-width: 0;
  min-height: 104px;
  .avatar {
    --width: 104px;
    --height: 104px;
    --font-size: 2.5rem;
    --font-weight: normal;

    align-self: flex-start;
  }
  .contact-information {
    margin-bottom: 1rem;
    .name {
      font-weight: bold;
      margin-bottom: 8px;
    }
    .metadata,
    .relation {
      color: var(--color-help-text);
      line-height: 1.5rem;
    }
  }
  .contact-right {
    min-width: 0;
  }
  .contact-actions {
    margin-top: 24px;
  }
}
