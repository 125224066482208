








































































.avatar-variables {
  --width: 48px;
  --height: 48px;
  --font-size: 1rem;
  --background-color: var(--color-primary-dark);
  --color: var(--color-white);
  --opacity: unset;
  --border: none;
  --border-radius: 50%;
}

.avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  aspect-ratio: 1/1;
  border-radius: var(--border-radius);
  overflow: hidden;
  opacity: var(--opacity);
  width: var(--width);
  height: var(--height);
  min-width: var(--width);
  background-color: var(--background-color);
  color: var(--color);
  border: var(--border);

  &[aria-disabled='true'] {
    --opacity: 0.5;
  }

  &[data-image] {
    border: none;
  }

  .overlay {
    position: absolute;
    inset: 0;
  }

  .avatar-image {
    --width: 100%;
    --height: 100%;
    object-position: 50% 4%;
  }

  .short-name {
    --zoom-factor: 1;
    font-size: calc(var(--font-size) * var(--zoom-factor));
    text-decoration: none;
    cursor: inherit;

    &.small {
      --zoom-factor: 0.65;
    }

    &.large {
      --zoom-factor: 1.2;
    }
  }

  .unknown-user {
    font-size: calc(var(--font-size) * var(--zoom-factor));
  }
}
