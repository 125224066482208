














.toolbar {
  display: flex;
  flex-wrap: wrap;
}

.primary-content {
  flex-grow: 1;
}
