










.buttons {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
