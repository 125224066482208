























.sound-thumbnail-variables {
  --width: 100%;
  --height: 100%;
}
.sound-thumbnail {
  position: relative;
  background-color: var(--color-grey-base);
  width: var(--width);
  height: var(--height);
}
.thumbnail-label {
  position: absolute;
  bottom: -28px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  color: var(--app-color-base);
}
.media-type-icon {
  --font-size: 64px;
}
