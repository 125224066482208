











































@import '../../../shared/assets/scss/core/breakpoints.scss';
.tag-details {
  @include breakpoint-lg() {
    max-height: var(--tag-details-max-height, calc(50vh - 200px));
    overflow-y: var(--tag-details-tags-overflow-y, auto);
  }
}
