



























































.video-thumbnail-variables {
  --width: 100%;
  --height: 100%;
  --thumbnail-object-fit: contain;
}
.video-thumbnail {
  position: relative;
  background-color: var(--color-grey-base);
  width: var(--width);
  height: var(--height);

  .image {
    --width: 100%;
    --height: 100%;
    --object-fit: var(--thumbnail-object-fit);
  }
}
.media-type-icon {
  --font-size: 64px;
}
.thumbnail-label {
  position: absolute;
  bottom: -28px;
  text-align: center;
  width: 100%;
  font-weight: bold;
  color: var(--app-color-base);
}
.thumbnail-circle[data-variant='secondary'] .thumbnail-label {
  color: var(--color-white);
}
