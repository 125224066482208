





@import '../assets/scss/core/breakpoints.scss';

.aula-badge-variables {
  --aula-badge-font-size: 10px;
  --aula-badge-size: 18px;
  @include breakpoint-lg() {
    --aula-badge-font-size: 12px;
    --aula-badge-size: 22px;
  }
}
.aula-badge {
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: var(--color-white);
  box-shadow: var(--aula-badge-box-shadow, 0 0 0 2px) var(--aula-badge-box-shadow-color);
  overflow: hidden;
  background-color: var(--color-alert);
  aspect-ratio: 1/1;
  width: var(--aula-badge-size);
  height: var(--aula-badge-size);
  font-size: var(--aula-badge-font-size);
  font-weight: 700;
}
