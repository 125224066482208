





.media-conversion-thumbnail {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  padding: 1rem;
  font-size: 1rem;
  background-color: var(--color-grey-base);
  color: var(--color-primary-darker);
  opacity: 0.8;
}
