
























































.alert-content {
  display: flex;
  flex: 1;
}

.alert-action {
  display: flex;
  align-items: center;
  padding: 0 16px;
  height: auto;
}
.aula-alert {
  display: flex;
  width: 100%;
  min-height: 50px;
  line-height: 22px;
  margin: 0 auto;
  background: var(--color-grey-base);
  border-radius: 5px;

  &[data-variant='alert'] {
    .alert-icon {
      background-color: var(--color-alert);
      color: var(--color-white);
      .alert-icon {
        --font-size: 24px;
      }
    }
  }

  .alert-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    background-color: var(--color-grey-dark);
    border-radius: 5px 0 0 5px;

    .circle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 34px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      background-color: var(--color-white);

      i {
        font-size: 15px;
      }
    }
  }

  .alert-text {
    flex: 1;
    margin: 4px 15px;
    align-self: center;
  }
}
