
























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































@import '../../../shared/assets/scss/core/variables.scss';
@import '../../../shared/assets/scss/core/breakpoints.scss';
.media-shared-with {
  /deep/ .dropdown-menu div {
    float: right;
  }
}
.print_tag {
  &:after {
    content: ', ';
  }
  &:last-child:after {
    content: '';
  }
}
.carousel.fromSearch /deep/ {
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
}
.media-creator {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 20px;
  width: 100%;
  @include breakpoint-lg() {
    margin-top: -20px;
  }
  .user {
    width: 50px;
  }
  .media-creator-infor {
    flex-grow: 1;
  }
  .media-creator-name {
    font-size: 16px;
    font-weight: bold;
  }
  .media-date {
    font-size: 14px;
    color: var(--color-grey);
  }
}

.media-info {
  overflow-wrap: break-word;
}

.failed-conversion-container {
  --questionmark-icon-background-color: var(--color-grey-base);
  border-top: 1px solid $color-grey-darker;
  border-bottom: 1px solid $color-grey-darker;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.small-thumbnail {
  &:not([aria-selected='true']) {
    opacity: 0.6;
  }
  > * {
    --width: 100px;
    --height: 100px;
  }
}
